import React, { useState, useEffect, useContext, useMemo } from 'react';
import { getReportDetails } from '../apiCalls';
import information from '../../images/information.svg'
import { Popover, OverlayTrigger, PopoverContent, Modal } from "react-bootstrap";
import { StoreContext } from "../Store";

const LoadDetails = ({headerData, nextPage, adjustments}) => {

  const [requestFailed, setRequestFailed] = useState(false);
  const [showAdjustmentsModal, setShowAdjustmentsModal] = useState(false);
  const [showPortModel, setShowPortModel] = useState(false);
  const [showCG, setShowCG] = useState(true);
  
  const isAdjustmentsInco = useMemo(() => {
    if (["DDP", "DDP LITE", "CFR", "CIF"].includes(headerData?.Incoterm))
      return false;
    return true;
   }, [headerData])

   useEffect(() => {
     if (headerData?.Movementtype) {
       if (![
           "NI - GB",
           "NI - GB via ROI",
           "ROI - GB",
           "ROI - GB via NI",
         ].includes(headerData?.Movementtype)
       ) {
         setShowPortModel(true);       
       }
     } else {
       setShowPortModel(false);
       
     }
   }, [headerData?.Movementtype]);

   useEffect(() => {
     if (headerData?.Movementtype) {
       if (
         [
           "EU - EU via GB",
           "GB - EU (mainland)",
           "GB - NI",
           "GB - NI via ROI",
           "GB - ROI",
           "GB - ROI via NI",
           "NI - GB via ROI",
           "GB - ROW"
         ].includes(headerData?.Movementtype)
       ) {
         setShowCG(false);
       }
     }
   }, [headerData?.Movementtype]);    

  if (requestFailed) return <p>Failed!</p>

  return (
    <div className="floating-box-newload ">
      <div className="floating-box-header-general">
        <span>Load Details</span>
      </div>
      <div id="load-info-form" className='loadlist-header'>
      <section className="inputs-group">
        <span className='section-title'>
          General Information
          </span>
        <div className="header-group">
            <div className="d-flex flex-column gap-2">
              <span className='load-info-info-titles fw-600'>Portal Reference</span>
              <span className='load-info-text mt-0 general-information-field' >{headerData.portalRef}</span>
            
            {headerData.companyName &&
            <>
              <span className='load-info-info-titles fw-600'>Company Name</span>
              <span className='load-info-text mt-0 general-information-field'>{headerData.companyName.slice(0,50)}</span>
            </>

              }
            {headerData.eoriNumber &&
            <>
              <span className='load-info-info-titles fw-600'>EORI Number</span>
              <span className='load-info-text mt-0 general-information-field'>{headerData.eoriNumber}</span>
            </>
 
            }
          </div>
      </div>
        </section>

        <section className="inputs-group">
          <span className='section-title'>Movement</span>
          <span className='load-info-info-titles'>Movement Type</span>
          <span className='load-info-text'>{headerData.Movementtype}</span>
          <span className='load-info-info-titles'>Full Load or Groupage</span>
          <span className='load-info-text'> {headerData.fcl}</span>
          <span className='load-info-info-titles'>Country of Despatch</span>
          <span className='load-info-text'>{headerData.Despatch}</span>
          <span className='load-info-info-titles'>Country of Destination</span>
          <span className='load-info-text'>{headerData.Destination}</span>
          <div className='load-info-info-titles d-flex align-items-center'>
            <span>Office of Destination (transit)</span>
          </div>
          <span className='load-info-text'>{headerData.officeOfDest}</span>
        </section>

        <section className="inputs-group">
          <span className='section-title'>Direction</span>
          <span className='load-info-info-titles'>Exit Port 1</span>
          <span className='load-info-text'>{headerData.ExitPort1}</span>
          <span className='load-info-info-titles'>Entry Port 1</span>
          <span className='load-info-text'> {headerData.EntryPort1}</span>
          {(showPortModel) && (<><span className='load-info-info-titles'>Port Model</span>
          <span className='load-info-text'>{headerData.portModal}</span></>)}

          {(headerData.ExitPort2) && (
            <>
            <span className='load-info-info-titles'>Exit Port 2</span>
            <span className='load-info-text'>{headerData.ExitPort2}</span>
            <span className='load-info-info-titles'>Entry Port 2</span>
            <span className='load-info-text'>{headerData.EntryPort2}</span>
            </>
            )}
        
          <span className='load-info-info-titles'>Consignor Code</span>
          <span className='load-info-text'><div>{headerData.Consignor}</div><div>{headerData.ConsignorEori}</div></span>
          <span className='load-info-info-titles'>Consignee Code</span>
          <span className='load-info-text'><div>{headerData.Consignee}</div><div>{headerData.ConsigneeEori}</div></span>
        </section>

        <section className="inputs-group">
          <span className='section-title'>Identification</span>
          <span className='load-info-info-titles'>Shipping Status</span>
          <span className='load-info-text'>{headerData.shipStatus}</span>
          <span className='load-info-info-titles'>Truck / trailer Number</span>
          <span className='load-info-text'> {headerData.truck}</span>
          <span className='load-info-info-titles'>Trailer seal Number</span>
          <span className='load-info-text'>{headerData.trailer}</span>
          <span className='load-info-info-titles'>Date of Loading</span>
          <span className='load-info-text'> {headerData.Loadingdate}</span>
          <span className='load-info-info-titles'>Container</span>
          <span className='load-info-text'> {headerData.imo}</span>
        </section>

        <section className="inputs-group">
          <span className='section-title'>Commercial</span>
          <span className='load-info-info-titles'>Job Reference</span>
          <span className='load-info-text'>{headerData.Jobreference}</span>
          <span className='load-info-info-titles'>Invoice Number</span>
          <span className='load-info-text'> {headerData.InvoiceNumber}</span>
          <span className='load-info-info-titles'>Invoice Currency</span>
          <span className='load-info-text'>{headerData.Currency}</span>
          <span className='load-info-info-titles'>Incoterm</span>
          <span className='load-info-text'> {headerData.Incoterm}</span>
          {showCG && headerData.Movementtype === "ROW - GB" && <span className='load-info-info-titles'>Frontier Required</span>}
          {showCG && headerData.Movementtype !== "ROW - GB" && <span className='load-info-info-titles'>Controlled Goods</span>}
          {showCG && <span className='load-info-text'>{
            ["Y", "N", "TRUE", "FALSE"].includes(headerData?.controlledGoods?.toUpperCase?.())
            ? {Y: "Yes", "N": "No", 'TRUE': 'Yes', 'FALSE': 'No' }[headerData.controlledGoods.toUpperCase()] 
            : headerData.controlledGoods}</span>}

            { isAdjustmentsInco && (
              <OverlayTrigger
              placement="left"
              trigger={["hover", "focus"]}
              overlay={
                <Popover style={{ minWidth: "15rem" }}>
                  <PopoverContent>
                    <ListLoadAdjustmentsPopover adjustments={adjustments} />
                  </PopoverContent>
                </Popover>
              }
            >
                      <button type="button" className="invoice-add-btn green-text" >
                      <span className="text-decoration-underline" style={{ fontSize: "1.1rem", marginBottom: "10px"}}>
                      Adjustments
                      </span>
                    </button>
            </OverlayTrigger> 
            )}

          <button className="blue-button-newload loads-btn stepper-button yellow" onClick={nextPage}>Items</button>
        </section>
      </div>
      {/* <div className='d-flex justify-content-end'>
          <button className="blue-button-newload loads-btn loads-btn-header" onClick={props.nextPage}>Items</button>
          </div> */}
    </div>

  )
}

export const ListLoadAdjustmentsPopover = ({adjustments, size, show, onHide}) => {
return (
          <div className="d-flex justify-content-center">
            <div>
            {adjustments.map((adjustment) => {
          return (
          <>
          
          <section style={{marginTop: '0px'}} className="d-flex flex-row">
          <p style={{marginTop: '40px'}}><b>{adjustment.display}</b></p>
              <div className="p-2" style={{width: '50%'}}>
                <div className='load-info-info-titles'>Amount</div>
                <div className='load-info-text'> {adjustment.amount}</div>         
              </div>
              <div className="p-2" style={{width: '50%'}}>
                <div className='load-info-info-titles'>Currency</div>
                <div className='load-info-text'> {adjustment.currency}</div>  
              </div>
          </section>
          </>
              )
      })}
            </div>

      </div>
);
};

export default LoadDetails;

